<template>
  <div class="fill-height" fluid>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card >
          <v-tabs v-model="tab" background-color="secondary" centered dark icons-and-text dense>
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="(tab, index) in tabs" :key="index" :to="tab.route" dense>
              {{ tab.name }}  
              <v-icon>{{ tab.icon }}</v-icon>
            </v-tab>

            <v-tab-item v-for="(tab, index) in tabs" :key="index" :value="tab.route">
              <router-view />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Support',
  data: () => ({
    tab: null,
  }),
  computed: {
    tabs() {
      return [
        {
          icon: "mdi-chat",
          name: "Chat's (Amere)",
          route: "amer",
        },
        {
          icon: "mdi-chat",
          name: "Chat's (Kenmare)",
          route: "kenmare",
        },
        {
          icon: "mdi-chat",
          name: "Chat's (Higest)",
          route: "higest",
        },
        {
          icon: "mdi-chat",
          name: "Chat's (Whirelab)",
          route: "chat",
        },
        {
          icon: "mdi-ticket",
          name: "Tickets",
          route: "ticket",
        },
        {
          icon: "mdi-message-alert",
          name: "Feedback",
          route: "feedback",
        },
      ];
    },
  }
}
</script>